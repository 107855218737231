@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    a {
        @apply text-text-link hover:underline hover:text-[#0d4da6];
    }
    b,
    strong {
        font-weight: bold;
    }
}

#chapter_1 > div:first-child > h2 {
    margin-top: 0;
}

.callout-box > div > .exhibit-group {
    max-width: 100%;
}

/** Marquee Header Placeholder Styling **/
.mq-header-container {
	height: 88px;
	width: 100%;
}

.mq-header-placeholder {
	background: #2e2f31;
	background: linear-gradient(rgb(14, 23, 31) 50%, rgb(28, 43, 54) 0);
	height: 88px;
	width: 100%;
}

/** Classes from bootstrap collapse functionality required for some interactive PTEs to work **/
.collapse{display:none}
.collapse.in{display:block;visibility:visible !important;}
.collapsing{position:relative;height:0;overflow:hidden;-webkit-transition:height .35s ease;transition:height .35s ease}